<template>
  <div>
    <van-nav-bar
      :title="navbarTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
      @click-right="sureClick"
    >
      <template #right>
        <span style="font-size: 16px; line-height: 22px; color: #2dd070">{{
          $t("mine.done")
        }}</span>
      </template>
    </van-nav-bar>
    <div id="content">
      <ul class="selet">
        <li @click="chooseChinese">
          <span class="text">{{ $t("language.chinese") }}</span>
          <van-icon name="success" class="sureIcon" size="18" v-if="chinese" />
        </li>
        <li @click="chooseEnglish">
          <span class="text">{{ $t("language.English") }}</span>
          <van-icon name="success" class="sureIcon" size="18" v-if="english" />
        </li>
        <li @click="chooseSpanish">
          <span class="text">{{ $t("language.SPANISH") }}</span>
          <van-icon name="success" class="sureIcon" size="18" v-if="spanish" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "language",
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      sure: "",
      navbarTitle: "",
      choosedLanguage: "",
      chinese: false,
      english: false,
      spanish: true ,
      message: "Lorem ipsum dolor sit amet ",
    };
  },
  created() {
    this.navbarTitle = this.$t("mine.Settings");
    this.getPath();
  },
  methods: {
    getPath() {
      if (this.$route.path == "/language") {
        if (window.localStorage.getItem('languageSet')) {
            if (localStorage.getItem("languageSet") == "zh") {
              this.chinese = true;
              this.english = false;
              this.spanish = false;
            } else if (localStorage.getItem("languageSet") == "en") {
              this.chinese = false;
              this.english = true;
              this.spanish = false;
            } else if (localStorage.getItem("languageSet") == "es") {
              this.chinese = false;
              this.english = false;
              this.spanish = true;
            }
        }else{
          this.chinese = false;
          this.english = false;
          this.spanish = true;
        }
      }
    },
    sureClick() {
      if (this.chinese) {
        this.$i18n.locale = "zh";
      } else if (this.english) {
        this.$i18n.locale = "en";
      } else if (this.spanish) {
        this.$i18n.locale = "es";
      }
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.back();
    },
    back() {
      this.$router.push("setting");
    },
    chooseChinese() {
      this.chinese = true;
      this.english = false;
      this.spanish = false;
    },
    chooseEnglish() {
      this.chinese = false;
      this.spanish = false;
      this.english = true;
    },
      chooseSpanish() {
      this.chinese = false;
      this.spanish = true;
      this.english = false;
    },
  },
};
</script>
<style scoped>
.van-nav-bar {
  background: #fafafa;
}
#content {
  background: #fafafa;
  padding: 0;
  height: calc(100vh - 60px);
  box-sizing: border-box;
}
.van-nav-bar__content {
  height: 60px;
}
.van-nav-bar__title {
  font-weight: bold;
}
.selet li {
  background: #fff;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  /* line-height: 56px; */
}
.selet .text {
  color: #333333;
  font-size: 14px;
}
.selet .sureIcon {
  font-size: 14px;
  color: #2dd070;
}
</style>
